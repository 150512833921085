import { useEffect, useState } from "react";
import { getDemografia } from "services/Reports";
import { Table } from "antd";
import ExportExcel from "components/excel/ExportExcel";
import Loader from "components/loader";
import dayjs from "dayjs";

const Demography = () => {
  const [demografia, setDemografia] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDemografia = async () => {
    try {
      setIsLoading(true)
      const data = await getDemografia()
      setIsLoading(false)

      if (data) {
        setDemografia(data[0]);
        dataExport(data[0])
      }

    } catch (error) {
      return error
    }
  }

  const columns = [
    {
      title: 'User_ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Username',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Apellido',
      dataIndex: 'apellido',
    },
    {
      title: 'Fecha de nacimiento',
      dataIndex: 'fecha_nac',
      sorter: (a, b) => dayjs(a.fecha_nac).unix() - dayjs(b.fecha_nac).unix(),
      render: (text) => dayjs(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Edad',
      dataIndex: 'Edad',
      sorter: (a, b) => a.Edad - b.Edad,
    },
    {
      title: 'Género',
      dataIndex: 'Genero',
    },
    {
      title: 'Ciudad',
      dataIndex: 'localidad',
    },
    {
      title: 'Provincia',
      dataIndex: 'provincia',
    },
    {
      title: 'Antiguedad en la plataforma',
      dataIndex: 'creado',
      sorter: (a, b) => dayjs(a.creado).unix() - dayjs(b.creado).unix(),
      render: (text) => dayjs(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Última actualización',
      dataIndex: 'actualizado',
      sorter: (a, b) => dayjs(a.actualizado).unix() - dayjs(b.actualizado).unix(),
      render: (text) => dayjs(text).format('DD/MM/YYYY'),
    },
  ];

  const dataExport = (data) => {
    const exportData = data.map((item) => {
      return {
        'User_ID': item.user_id,
        'Username': item.name,
        'Email': item.email,
        'Nombre': item.nombre,
        'Apellido': item.apellido,
        'Fecha de nacimiento': dayjs(item.fecha_nac).format('DD/MM/YYYY'),
        'Edad': item.Edad,
        'Género': item.Genero,
        'Ciudad': item.localidad,
        'Provincia': item.provincia,
        'Antiguedad en la plataforma': dayjs(item.creado).format('DD/MM/YYYY'),
        'Última actualización': dayjs(item.actualizado).format('DD/MM/YYYY')
      }
    })

    setDataExcel(exportData)
  }

  useEffect(() => {
    fetchDemografia()
  }, [])

  return (
    <>
      <div className="flex flex-row gap-5">
        <h1 className="text-3xl font-medium mb-5">/ Demografia</h1>
        <ExportExcel data={dataExcel} fileName={"Demografia_" + new Date().toLocaleDateString()} title="Descargar Excel" />
      </div>

      {isLoading ? <Loader /> :
        <Table
          columns={columns}
          dataSource={demografia}
          onChange={(pagination, filters, sorter, extra) => {
            dataExport(extra.currentDataSource)
          }}
        />
      }
    </>
  );
};

export default Demography;
