import React, { useEffect, useState } from 'react';
import { Avatar, List, message } from 'antd';
import VirtualList from 'rc-virtual-list';
import dayjs from 'dayjs';

const ContainerHeight = 350;

const LogList = ({ logs }) => {

  return (
    <List>
      <VirtualList
        data={logs.reverse()}
        height={ContainerHeight}
        itemHeight={47}
        itemKey="logs"
      >
        {
          (item) => (
            item.field === 'plan_id' ?
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={item.detail}
                  description={
                    `Cambio de plan ${item.old_value === '1' ? 'Gold' : 'Standard'} > plan ${item.new_value === '1' ? 'Gold' : 'Standard'}`
                  }
                />
              </List.Item>
              :
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={`Cambio de fecha de vencimiento plan GOLD a ${dayjs(item.new_value).format('DD/MM/YYYY')}`}
                  description={
                    `Anotaciones: ${item.detail}`
                  }
                />
              </List.Item>
          )
        }


      </VirtualList>
    </List>
  );
};


export default LogList;