import MiniCalendar from "components/calendar/MiniCalendar";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import SelectUseForm from "components/SelectUseForm";
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import ExportExcel from "components/excel/ExportExcel";
import { IoMdHome, IoMdDocument, IoMdSchool } from "react-icons/io";
import { MdBarChart, MdDocumentScanner } from "react-icons/md";
import { FaSchool, FaUsers, FaRobot } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { Button } from "antd"
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import useStats from "hooks/useStats";
import { useEffect, useState } from "react";
import Loader from "components/loader";
import usePublications from "hooks/usePublications";
import useUsers from "hooks/useUsers";
import ProvincesTable from "./components/ProvincesTable";


const Dashboard = () => {
  const { isLoading, fetchData } = useStats('main')
  const [stats, setStats] = useState()
  const { isLoading: isLoadingPublications, response, fetchData: fetchPublications, getPostulateds } = usePublications()
  const [totalPostulaciones, setTotalPostulaciones] = useState([])
  const [totalNoEspontaneas, setTotalNoEspontaneas] = useState([])
  const [totalEspontaneas, setTotalEspontaneas] = useState([])
  const [totalInstCPubActivas, setTotalInstCPubActivas] = useState([])
  const [totalPostulantes, seTotalPostulantes] = useState(0)
  const [totalPostPublicos, seTotalPostPublicos] = useState(0)
  const [totalUsers, setTotalUsers] = useState([])
  const [perfilesAbiertos, setPerfilesAbiertos] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [cvsConIA, setCvsConIA] = useState(0)
  const [cvsIncompletos, setCvsIncompletos] = useState(0)
  const [currentDesde, setCurrentDesde] = useState(dayjs(new Date()).format('YYYY-MM'))
  const [currentHasta, setCurrentHasta] = useState(dayjs(new Date()).format('YYYY-MM'))
  const [totalPublicaciones, setTotalPublicaciones] = useState([])
  const [dataExcel, setDataExcel] = useState([]);
  const [dataExcelAcumulada, setDataExcelAcumulada] = useState([]);
  const [visible, setVisible] = useState(false);
  const [postulantes, setPostulantes] = useState([])
  const [instituciones, setInstituciones] = useState([])

  const [totalInst, setTotalInst] = useState(0)
  const [totalInstFiltrado, setTotalInstFiltrado] = useState(0)
  const [totalPubAct, setTotalPubAct] = useState(0)
  const [totalPubALaFecha, setTotalPubALaFecha] = useState(0)
  const [insPubAct, setInsPubAct] = useState('')
  const [totPost, setTotPost] = useState(0)
  const [totPostPub, setTotPostPub] = useState('')
  const [totPostu, setTotPostu] = useState(0)
  const [totEspon, setTotEspon] = useState('')
  const [totNoEspon, setTotNoEspon] = useState('')
  const [totPostFiltrado, setTotPostFiltrado] = useState(0)
  const [meses, setMeses] = useState([])

  const { register, handleSubmit, reset, control, setValue, formState: { errors } } = useForm();
  const { isLoading: isLoadingUsers, response: users, fetchData: getUsers } = useUsers();



  function generarRangos() {
    const nombresMeses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const startYear = 2023;
    const startMonth = 10;
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    const meses = [];
    for (let year = startYear; year <= currentYear; year++) {
      const mesInicial = year === startYear ? startMonth : 0;
      const mesFinal = year === currentYear ? currentMonth : 11;

      for (let month = mesInicial; month <= mesFinal; month++) {
        const value = `${year}-${String(month + 1).padStart(2, "0")}`;
        const name = `${nombresMeses[month]} ${year}`;
        meses.push({ name, value });
      }
    }
    setMeses(meses)
  }


  const fetchStats = async () => {
    const data = await fetchData()
    setStats(data)
  }

  const fetchGetPublications = async () => {
    const data = await fetchPublications(null, {}, true)
    const fechaActual = new Date().toISOString().split('T')[0];

    // console.log(data.data.filter(pub => pub.cuenta_id == 2))
    let totalpubActivas = data?.data?.filter(pub => pub?.activa === 1 && pub?.aprobada === 1)

    const instituciones = Object.values(
      totalpubActivas.reduce((acc, pub) => {
        const institucionId = pub.cuenta.id;
        acc[institucionId] = acc[institucionId] || { ...pub.cuenta, totalpubActivas: [] };
        acc[institucionId].totalpubActivas.push(pub);
        return acc;
      }, {})
    );

    setTotalPublicaciones(data.data)
    setTotalInstCPubActivas(instituciones);
  }


  const institucionesPublicacioneActivas = (data) => {
    const institucionesUnicas = new Set(data.map(pub => pub?.cuenta?.user_id));
    const totalInstituciones = institucionesUnicas.size;

    return totalInstituciones;
  };


  const fetchDataPostulations = async () => {
    setLoadingData(true)
    const data = await getPostulateds(null, 5, true)

    setTotalEspontaneas(data.filter(publication => publication?.publicacion_id === null))
    setTotalNoEspontaneas(data.filter(publication => publication?.publicacion_id !== null))
    setTotalPostulaciones(data)

    setLoadingData(false)
  }

  const fetchUsers = async () => {
    const data = await getUsers(null, true)

    setCvsConIA(data.filter(user => user?.cuenta?.activo === 1).filter(user => user?.cuenta?.disponible_ia === -1).length)
    setTotalUsers(data.filter(user => user?.cuenta?.activo === 1))
    setPostulantes(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.activo === 1))
    setInstituciones(data.filter(user => user?.type === 'I').filter(user => user?.cuenta?.activo === 1))

    setCvsIncompletos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.activo === 1).filter(user => user?.cuenta?.fecha_perfil_completo === null).length)
    seTotalPostulantes(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.activo === 1).length)
    seTotalPostPublicos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.activo === 1).filter(user => user?.cuenta?.perfil_publico === 1).length)
  }

  function isDateInRange(createdAt, start, end) {
    if (createdAt && start && end) {
      const startDate = new Date(`${start}-01T00:00:00`);
      const [year, month] = end.split('-');
      const endDate = new Date(year, month, 0); // Día 0 del siguiente mes es el último día del mes actual

      // Convertir createdAt a fecha y normalizar (solo año, mes, día)
      const createdDate = new Date(createdAt);
      createdDate.setHours(0, 0, 0, 0);

      // Normalizar startDate y endDate para asegurarnos que no haya problemas con las horas
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999); // Aseguramos el final del día para incluir el día 31 completamente

      return createdDate >= startDate && createdDate <= endDate;
    }

    return false;
  }


  const handleFilterRange = () => {
    if (currentHasta < currentDesde) {
      return alert('La fecha hasta no puede ser menor a la fecha desde');
    }

    const filterPostulantesHasta = postulantes.filter(cuenta => isDateInRange(cuenta?.created_at, '2023-11', currentHasta));
    const filterPostulantesRango = postulantes.filter(cuenta => isDateInRange(cuenta?.created_at, currentDesde, currentHasta));

    const filterInstitucionesHasta = instituciones.filter(cuenta => isDateInRange(cuenta?.created_at, '2023-11', currentHasta));
    const filterInstitucionesRango = instituciones.filter(cuenta => isDateInRange(cuenta?.created_at, currentDesde, currentHasta));

    const totalPostulan = filterPostulantesHasta.length;
    const totalPostulanFiltrado = filterPostulantesRango.length;
    const totalInstituciones = filterInstitucionesHasta.length;
    const totalInstitucionesFiltradas = filterInstitucionesRango.length;

    const postulacionesTotales = totalPostulaciones?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length;
    const totalPublicacionesActivas = totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length;
    const totalPublicacionesALaFecha = totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, '2023-11', currentHasta)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length;

    const institucionesCPublicacionesActivas = (institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))) * 100 / totalInstituciones).toFixed(2) + '% ' + '(' + institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))).toLocaleString('es-AR') + ')';
    const totalPubs = ((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '% ' + '(' + totalPostPublicos?.toLocaleString('es-AR') + ')';

    const totalPostEspontaneas = totalEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')';
    const totalPostNoEspontaneas = totalNoEspontaneas?.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length + ' ' + '(' + (totalNoEspontaneas.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, currentDesde, currentHasta)).length).toFixed(2) + '%' + ')';

    setVisible(true);
    setTotalInst(totalInstituciones);
    setTotalInstFiltrado(totalInstitucionesFiltradas);
    setTotalPubAct(totalPublicacionesActivas);
    setTotalPubALaFecha(totalPublicacionesALaFecha);
    setInsPubAct(institucionesCPublicacionesActivas);
    setTotPost(totalPostulan);
    setTotPostFiltrado(totalPostulanFiltrado);
    setTotPostPub(totalPubs);
    setTotPostu(postulacionesTotales || 0);
    setTotEspon(totalPostEspontaneas);
    setTotNoEspon(totalPostNoEspontaneas);

    const data = {
      Total_instituciones: totalInstituciones,
      Registradas: totalInstitucionesFiltradas,
      Publicaciones_a_la_fecha: totalPublicacionesALaFecha,
      Publicaciones_activas: totalPublicacionesActivas,
      Instituciones_con_publicaciones_activas: institucionesCPublicacionesActivas,
      Porcentaje_Instituciones_con_publicaciones_activas: institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, currentDesde, currentHasta))).toLocaleString('es-AR'),
      Total_postulantes: totalPostulan,
      Total_postulantes_filtrados: totalPostulanFiltrado,
      Postulaciones_totales: postulacionesTotales,
      Postulaciones_espontaneas: totalPostEspontaneas,
      Postulaciones_no_espontaneas: totalPostNoEspontaneas,
      Postulantes_con_perfil_abierto: totalPostPublicos?.toLocaleString('es-AR'),
      Porcentaje_postulantes_con_perfil_abierto: ((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '% ',
      Postulantes_con_CV_con_IA: cvsConIA,
      Postulantes_con_perfil_incompleto: cvsIncompletos,
      Porcentaje_postulantes_con_perfil_incompleto: (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%',
    };

    const dataAcumulada = meses.map((mes) => ({
      Periodo: mes.value,
      Total_instituciones: instituciones.filter(user => isDateInRange(user?.created_at, '2023-11', mes.value)).length,
      Registradas: instituciones.filter(user => isDateInRange(user?.created_at, mes.value, mes.value)).length,
      Publicaciones_a_la_fecha: totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, '2023-11', mes.value)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length,
      Publicaciones_activas: totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, mes.value, mes.value)).filter(pub => pub?.activa === 1 && pub?.aprobada === 1).length,
      Instituciones_con_publicaciones_activas: (institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, mes.value, mes.value))) * 100 / instituciones.length).toFixed(2) + '% (' + institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, mes.value, mes.value))).toLocaleString('es-AR') + ')',
      Porcentaje_Instituciones_con_publicaciones_activas: institucionesPublicacioneActivas(totalPublicaciones?.filter(pub => isDateInRange(pub?.created_at, mes.value, mes.value))).toLocaleString('es-AR'),
      Total_postulantes: postulantes.filter(post => isDateInRange(post?.created_at, '2023-11', mes.value)).length,
      Total_postulantes_filtrados: postulantes.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length,
      Postulaciones_totales: totalPostulaciones?.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length,
      Postulaciones_espontaneas: totalEspontaneas?.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length + ' (' + (totalEspontaneas.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length).toFixed(2) + '%)',
      Postulaciones_no_espontaneas: totalNoEspontaneas?.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length + ' (' + (totalNoEspontaneas.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length * 100 / totalPostulaciones.filter(post => isDateInRange(post?.created_at, mes.value, mes.value)).length).toFixed(2) + '%)',
      Postulantes_con_perfil_abierto: totalPostPublicos?.toLocaleString('es-AR'),
      Porcentaje_postulantes_con_perfil_abierto: ((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '%',
      Postulantes_con_CV_con_IA: cvsConIA,
      Postulantes_con_perfil_incompleto: cvsIncompletos,
      Porcentaje_postulantes_con_perfil_incompleto: (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%',
    }));

    dataExportAcumulado(dataAcumulada);
    dataExport(data);
  };


  const dataExportAcumulado = (data) => {

    const exportData = data.map((mes) => ({
      "Periodo": mes.Periodo,
      'Instituciones (acumulado)': mes.Total_instituciones,
      'Instituciones (del rango)': mes.Registradas,
      'Publicaciones (acumulado)': mes.Publicaciones_a_la_fecha,
      'Publicaciones (del rango)': mes.Publicaciones_activas,
      'Instituciones/publicaciones (del rango)': mes.Instituciones_con_publicaciones_activas,
      '%Instituciones/publicaciones (del rango)': mes.Porcentaje_Instituciones_con_publicaciones_activas,
      'Postulantes (acumulado)': mes.Total_postulantes,
      'Postulantes (del rango)': mes.Total_postulantes_filtrados,
      'Postulaciones totales': mes.Postulaciones_totales,
      'Postulaciones espontáneas': mes.Postulaciones_espontaneas,
      'Postulaciones directas': mes.Postulaciones_no_espontaneas,
      'Postulantes con perfil abierto': mes.Postulantes_con_perfil_abierto,
      '% Postulantes con perfil abierto': mes.Porcentaje_postulantes_con_perfil_abierto,
      'Postulantes que cargaron CV con IA': mes.Postulantes_con_CV_con_IA,
      'Postulantes con perfil incompleto': mes.Postulantes_con_perfil_incompleto,
      '% Postulantes con perfil incompleto': mes.Porcentaje_postulantes_con_perfil_incompleto,
    }))

    setDataExcelAcumulada(exportData)
  }

  const dataExport = (data) => {

    const exportData = {
      "Periodo": currentDesde + ' - ' + currentHasta,
      'Instituciones (acumulado)': data.Total_instituciones,
      'Instituciones (del rango)': data.Registradas,
      'Publicaciones (acumulado)': data.Publicaciones_a_la_fecha,
      'Publicaciones (del rango)': data.Publicaciones_activas,
      'Instituciones/publicaciones (del rango)': data.Instituciones_con_publicaciones_activas,
      '%Instituciones/publicaciones (del rango)': data.Porcentaje_Instituciones_con_publicaciones_activas,
      'Postulantes (acumulado)': data.Total_postulantes,
      'Postulantes (del rango)': data.Total_postulantes_filtrados,
      'Postulaciones totales': data.Postulaciones_totales,
      'Postulaciones espontáneas': data.Postulaciones_espontaneas,
      'Postulaciones directas': data.Postulaciones_no_espontaneas,
      'Postulantes con perfil abierto': data.Postulantes_con_perfil_abierto,
      '% Postulantes con perfil abierto': data.Porcentaje_postulantes_con_perfil_abierto,
      'Postulantes que cargaron CV con IA': data.Postulantes_con_CV_con_IA,
      'Postulantes con perfil incompleto': data.Postulantes_con_perfil_incompleto,
      '% Postulantes con perfil incompleto': data.Porcentaje_postulantes_con_perfil_incompleto
    }

    setDataExcel(exportData)
  }

  const changeDesde = (e) => {
    setCurrentDesde(e.target.value)
  }

  const changeHasta = (e) => {
    setCurrentHasta(e.target.value)
  }


  useEffect(() => {
    generarRangos();
    fetchStats()
    fetchDataPostulations()
    fetchGetPublications()
    fetchUsers()
    setValue('desde', dayjs(new Date()).format('YYYY-MM'))
    setValue('hasta', dayjs(new Date()).format('YYYY-MM'))
  }, [])


  return (

    <div>
      {/* Card widget */}

      <div className="flex flex-row justify-start items-start gap-2">
        <SelectUseForm
          // label="Desde"
          name="desde"
          register={register}
          onChange={changeDesde}
          errors={errors}
          className="w-[10%]"
          options={meses}
        />
        <SelectUseForm
          // label="Hasta"
          name="hasta"
          register={register}
          onChange={changeHasta}
          errors={errors}
          className="w-[10%]"
          options={meses}
        />

        <div className="flex flex-row justify-between items-center gap-3 mt-1">
          <Button
            onClick={handleFilterRange}
            type="primary"
            className="text-xl primary"
          >
            Buscar
          </Button>
          <div className={`${visible ? 'd-block' : 'hidden'} flex flex-row`}>
            <ExportExcel data={dataExcel} fileName={"Filtrado_" + currentDesde + "_" + currentHasta} title={"Descargar Rango"} />
            <ExportExcel data={dataExcelAcumulada} fileName={"Consolidado_anual"} title={"Descargar Acumulado"} />
          </div>
        </div>

      </div>




      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<FaSchool className="h-6 w-6" />}
          title={"Instituciones (acumulado)"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : totalInst}
        />

        <Widget
          icon={<FaSchool className="h-6 w-6" />}
          title={"Instituciones (del rango)"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : totalInstFiltrado}
        />

        <Widget
          icon={<IoMdDocument className="h-6 w-6" />}
          title={"Publicaciones (acumulado)"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : totalPubALaFecha}
        />

        <Widget
          icon={<IoMdDocument className="h-6 w-6" />}
          title={"Publicaciones (del rango)"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : totalPubAct}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"% Instituciones/publicaciones (del rango)"}
          subtitle={isLoadingPublications ? <Loader small={true} /> : insPubAct}
        />

      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<IoMdSchool className="h-7 w-7" />}
          title={"Postulantes (acumulado)"}
          subtitle={isLoading ? <Loader small={true} /> : totPost}

        />

        <Widget
          icon={<IoMdSchool className="h-7 w-7" />}
          title={"Postulantes (del rango)"}
          subtitle={isLoading ? <Loader small={true} /> : totPostFiltrado}

        />

        <Widget
          icon={<FaUsers className="h-6 w-6" />}
          title={"% Postulantes con perfil abierto"}
          subtitle={loadingData ? <Loader small={true} /> : totPostPub}
        />

        <Widget
          icon={<FaRobot className="h-6 w-6" />}
          title={"Postulantes que cargaron CV con IA"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsConIA}
        />

        <Widget
          icon={<MdDocumentScanner className="h-6 w-6" />}
          title={"Postulantes con perfil incompleto"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsIncompletos + ' ' + '(' + (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%' + ')'}
        />

        <Widget
          icon={<IoDocumentsSharp className="h-7 w-7" />}
          title={"Postulaciones totales"}
          subtitle={loadingData ? <Loader small={true} /> : totPostu}
          secondTitle={"Espontáneas"}
          secondSubtitle={loadingData ? <Loader small={true} /> : totEspon}
          thirdTitle={"Directas"}
          thirdSubtitle={loadingData ? <Loader small={true} /> : totNoEspon}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 w-full">
        <TotalSpent users={totalUsers} />
        {/* <WeeklyRevenue /> */}
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5">
        <ComplexTable />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}


        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <MiniCalendar />
        </div>
        <PieChartCard />

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5">

        <CheckTable />
        <ProvincesTable />
      </div>


    </div >
  );
};

export default Dashboard;
