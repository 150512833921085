import React, { useEffect, useState } from "react";
import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
// import {
//   lineChartDataTotalSpent,
//   lineChartOptionsTotalSpent,
// } from "variables/charts";
import LineChart from "components/charts/LineChart";
import { Select } from "antd";
import Loader from "components/loader";
import { set } from "lodash";

// import { data } from "autoprefixer";

const TotalSpent = ({ users }) => {

  // const [postulantes, setPostulantes] = useState([]);
  // const [instituciones, setInstituciones] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [totalPorMesPostulantes, setTotalPorMesPostulantes] = useState([]);
  const [totalPorMesInstituciones, setTotalPorMesInstituciones] = useState([]);
  const [totalPorDiaPostulantes, setTotalPorDiaPostulantes] = useState([]);
  const [totalPorDiaInstituciones, setTotalPorDiaInstituciones] = useState([]);
  // const [dataXAxis, setDataXAxis] = useState([]);
  const [dataYAxisPostulantes, setDataYAxisPostulantes] = useState([]);
  const [dataYAxisInstituciones, setDataYAxisInstituciones] = useState([]);
  const [totalPorMes, setTotalPorMes] = useState([])
  const [diasMesSeleccionado, setDiasMesSeleccionado] = useState(0);
  const [totalUsuariosPorMes, setTotalUsuariosPorMes] = useState([]);
  // const [currentMonthX, setCurrentMonthX] = useState([])
  // const [currentMonthYPostulantes, setCurrentMonthYPostulantes] = useState([])
  // const [currentMonthYInstituciones, setCurrentMonthYInstituciones] = useState([])

  const lineChartDataTotalSpent = [
    {
      name: "Total usuarios",
      data: totalUsuariosPorMes,
      color: "#ff572f",
    },
    {
      name: "Postulantes",
      data: dataYAxisPostulantes,
      color: "#FFD625",
    },
    {
      name: "Instituciones",
      data: dataYAxisInstituciones,
      color: "#000000",
    },
  ];

  const lineChartOptionsTotalSpent = {
    legend: {
      show: true,
    },

    theme: {
      mode: "light",
    },

    chart: {
      type: "line",

      toolbar: {
        show: true,
      },

      zoom: {
        enabled: true,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000"
      },
      theme: 'dark',
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    grid: {
      show: false,
    },

    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },

      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },

      type: "text",
      range: undefined,
      categories: diasMesSeleccionado,
    },
  };


  //Muestra totales de usuarios
  // const calculatePostuByDays = () => {
  //   const publicacionesPorDia = users?.reduce((acumulador, pub) => {
  //     const created = pub?.created_at?.split('T')[0];
  //     if (!created) return acumulador;

  //     acumulador[created] = (acumulador[created] || 0) + 1;
  //     return acumulador;
  //   }, {});

  //   const arrayDeFechas = Object.entries(publicacionesPorDia).map(([fecha, cantidad]) => ({
  //     fecha,
  //     cantidad,
  //   }));

  //   const publicacionesPorMes = arrayDeFechas.reduce((acumulador, { fecha, cantidad }) => {
  //     const [year, month] = fecha.split('-').slice(0, 2);
  //     const key = `${month}/${year}`;

  //     if (!acumulador[key]) {
  //       acumulador[key] = { fecha: key, cantidad: 0 };
  //     }
  //     acumulador[key].cantidad += cantidad;

  //     return acumulador;
  //   }, {});

  //   const resultado = Object.values(publicacionesPorMes);

  //   setTotalPorDia(arrayDeFechas);
  //   setTotalPorMes(resultado);
  // };

  const calculatePostuByDays = () => {

    const publicacionesPorDiaPostulantes = users.filter(user => user?.type === 'P')?.reduce((acumulador, pub) => {
      const created = pub?.created_at?.split('T')[0];
      if (!created) return acumulador;

      acumulador[created] = (acumulador[created] || 0) + 1;
      return acumulador;
    }, {});

    const publicacionesPorDiaInstituciones = users.filter(user => user?.type === 'I').filter(user => user?.activo === 1)?.reduce((acumulador, pub) => {
      const created = pub?.created_at?.split('T')[0];
      if (!created) return acumulador;

      acumulador[created] = (acumulador[created] || 0) + 1;
      return acumulador;
    }, {});

    const arrayDeFechasPostulantes = Object.entries(publicacionesPorDiaPostulantes).map(([fecha, cantidad]) => ({
      fecha,
      cantidad,
    }));

    const arrayDeFechasInstituciones = Object.entries(publicacionesPorDiaInstituciones).map(([fecha, cantidad]) => ({
      fecha,
      cantidad,
    }));

    const publicacionesPorMesPostulantes = arrayDeFechasPostulantes.reduce((acumulador, { fecha, cantidad }) => {
      const [year, month] = fecha.split('-').slice(0, 2);
      const key = `${month}/${year}`;

      if (!acumulador[key]) {
        acumulador[key] = { fecha: key, cantidad: 0 };
      }
      acumulador[key].cantidad += cantidad;

      return acumulador;
    }, {});

    const publicacionesPorMesInstituciones = arrayDeFechasInstituciones.reduce((acumulador, { fecha, cantidad }) => {
      const [year, month] = fecha.split('-').slice(0, 2);
      const key = `${month}/${year}`;

      if (!acumulador[key]) {
        acumulador[key] = { fecha: key, cantidad: 0 };
      }
      acumulador[key].cantidad += cantidad;

      return acumulador;
    }, {});

    const resultadoPostulantes = Object.values(publicacionesPorMesPostulantes);
    const resultadoInstituciones = Object.values(publicacionesPorMesInstituciones);

    // const resultado = sumarPorFecha(resultadoPostulantes, resultadoInstituciones);
    // setTotalUsuariosPorMes(resultado);

    setTotalPorMesPostulantes(resultadoPostulantes);
    setTotalPorMesInstituciones(resultadoInstituciones);
    setTotalPorMes(sumarFechas(resultadoPostulantes, resultadoInstituciones))

    setTotalPorDiaPostulantes(arrayDeFechasPostulantes);
    setTotalPorDiaInstituciones(arrayDeFechasInstituciones);
  };

  // function sumarPorFecha(arr1, arr2) {
  //   const map = new Map();

  //   // Agregar cantidades del primer array
  //   arr1.forEach(({ fecha, cantidad }) => {
  //     map.set(fecha, (map.get(fecha) || 0) + cantidad);
  //   });

  //   // Agregar cantidades del segundo array
  //   arr2.forEach(({ fecha, cantidad }) => {
  //     map.set(fecha, (map.get(fecha) || 0) + cantidad);
  //   });

  //   // Convertir el Map a un array de objetos
  //   return Array.from(map, ([fecha, cantidad]) => ({ fecha, cantidad }));
  // }

  const sumarFechas = (postulantes, instituciones) => {
    const mapa = new Map();

    // Iteramos sobre ambos arrays y acumulamos las cantidades
    [...postulantes, ...instituciones].forEach(({ fecha, cantidad }) => {
      if (mapa.has(fecha)) {
        mapa.set(fecha, mapa.get(fecha) + cantidad); // Sumamos las cantidades si ya existe la fecha
      } else {
        mapa.set(fecha, cantidad); // Creamos la entrada si no existe
      }
    });

    // Convertimos el mapa a un array de objetos
    return Array.from(mapa, ([fecha, cantidad]) => ({ fecha, cantidad }));
  }

  const handleChange = (date, dateString) => {
    const month = dateString.label.split('/')[1]; // Mes
    const year = dateString.label.split('/')[0];  // Año

    // Filtra los días del mes seleccionado
    const diasPostulantes = totalPorDiaPostulantes.filter((dia) =>
      dia.fecha.includes(`${month}-${year}`)
    );

    const diasInstituciones = totalPorDiaInstituciones.filter((dia) =>
      dia.fecha.includes(`${month}-${year}`)
    );

    const daysInMonth = new Date(year, month, 0).getDate(); // Cantidad de días en el mes

    // Genera todos los días del mes con formato "01", "02", ..., "30"
    const todosLosDias = Array.from({ length: daysInMonth }, (_, i) =>
      String(i + 1).padStart(2, '0')
    );

    // Crea un mapa para obtener rápidamente la cantidad por día
    const cantidadPorDiaPostulantes = diasPostulantes.reduce((acc, dia) => {
      const day = dia.fecha.slice(8, 10); // Extrae el día del "YYYY-MM-DD"
      acc[day] = dia.cantidad;
      return acc;
    }, {});

    const cantidadPorDiaInstituciones = diasInstituciones.reduce((acc, dia) => {
      const day = dia.fecha.slice(8, 10); // Extrae el día del "YYYY-MM-DD"
      acc[day] = dia.cantidad;
      return acc;
    }, {});

    // Genera el array de valores para el eje Y (completa con 0 si no existe el día)
    const dataYAxisPostulantes = todosLosDias.map((dia) => cantidadPorDiaPostulantes[dia] || 0);
    const dataYAxisInstituciones = todosLosDias.map((dia) => cantidadPorDiaInstituciones[dia] || 0);

    setDiasMesSeleccionado(todosLosDias); // Array con todos los días
    // setDataXAxis(todosLosDias);

    setTotalUsuariosPorMes(dataYAxisPostulantes.map((valor, index) => valor + dataYAxisInstituciones[index]));

    setDataYAxisPostulantes(dataYAxisPostulantes); // Eje Y con valores completos
    setDataYAxisInstituciones(dataYAxisInstituciones); // Eje Y con valores completos
  };


  // const getPostulantes = () => {
  //   const postulateds = users.filter(user => user?.type === 'P')
  //   const institutions = users.filter(user => user?.type === 'I').filter(user => user?.activo === 1)

  //   setPostulantes(postulateds)
  //   setInstituciones(institutions)
  // }

  // const getCurrentMonthCoordinates = () => {
  //   const now = new Date(Date.now());

  //   const formattedDate = now.toLocaleDateString('es-AR', {
  //     month: '2-digit',
  //     year: 'numeric',
  //   });


  //   const postulantes = totalPorMesPostulantes.filter((total) => total?.fecha === formattedDate)[0]?.cantidad
  //   const instituciones = totalPorMesInstituciones.filter((total) => total?.fecha === formattedDate)[0]?.cantidad
  //   console.log(totalPorMesPostulantes.length)
  //   console.log(totalPorMesInstituciones.length)

  //   setCurrentMonthX(formattedDate) // Ejemplo: "10/2024"
  //   // setCurrentMonthYPostulantes(totalPorMesPostulantes.filter((total) => total.fecha === formattedDate)[0].cantidad)
  //   // setCurrentMonthYInstituciones(totalPorMesInstituciones.filter((total) => total.fecha === formattedDate)[0].cantidad)
  // }

  useEffect(() => {
    // getPostulantes()
    calculatePostuByDays()
    // getCurrentMonthCoordinates()
  }, [users])


  return (
    <Card extra="!p-[20px] text-center h-[500px]">
      <div className="flex justify-between">
        <button className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80">
          <MdOutlineCalendarToday />

          <Select
            defaultValue="Rango"
            style={{ width: 120 }}
            onChange={handleChange}
            options={totalPorMes.map((fecha, key) => ({ value: key, label: fecha.fecha, cantidad: fecha.cantidad }))}
          />

        </button>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            {users.length.toLocaleString('es-AR')}
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Total usuarios</p>
            {/* <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500"> +2.45% </p>
            </div> */}
          </div>
        </div>
        <div className="h-full w-full">
          {
            isLoading ? <Loader /> :
              <LineChart
                options={lineChartOptionsTotalSpent}
                series={lineChartDataTotalSpent}
              />
          }

        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
