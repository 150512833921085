import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getUsuarios = async () => {
    try {
        const data = await HttpGet(`admin/usuarios`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getUsuario = async (id) => {
    try {
        const data = await HttpGet(`admin/usuarios/${id}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const verifyAndActivate = async (id, payload) => {
    try {
        const data = await HttpPut(`admin/usuarios/estado/${id}`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}

export const userChagePlan = async (userId, planId) => {
    try {
        const data = await HttpGet(`admin/usuarios/plan/${userId}/${planId}`)
        return data;
    } catch (error) {
        return error
    }
}

export const deleteUserData = async (userId) => {
    try {
        const data = await HttpDelete(`admin/usuarios/${userId}`)
        return data;
    } catch (error) {
        return error
    }
}


export const getCurriculumPDF = async (userId) => {
    //Esta endpoint verifica si existe, si no, lo crea
    try {
        const data = await HttpGet(`pdf/cv/${userId}`)
        return data;
    } catch (error) {
        return error
    }
}

export const getCurriculumPDFPostulateds = async (publicationId) => {
    //Esta endpoint verifica si existe, si no, lo crea
    try {
        const data = await HttpGet(`pdf/publicacion/${publicationId}`)
        return data;
    } catch (error) {
        return error
    }
}

export const changePlanVto = async (data) => {
    try {
        const response = await HttpPost(`admin/usuarios/cambiovencimiento`, JSON.stringify(data))
        return response;
    } catch (error) {
        return error
    }
}
        