import AdminLayout from "layouts/admin";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { routesNames } from "utils/Constants";
import Dashboard from "views/admin/default";
import Publications from "views/admin/publications";
import SignIn from "views/auth/SignIn";
// import Plans from "views/admin/plans";
import Plan from "views/admin/plan";
import Publication from "views/admin/publication";
import PlansSettings from "views/admin/settings/plans";
import PublicationsSettings from "views/admin/settings/publications";
// import Marketplace from "views/admin/marketplace";
import CMS from "views/admin/cms";
import Contact from "views/admin/cms/contact";
import Faqs from "views/admin/cms/faqs";
import Transfer from "views/admin/cms/transfer";
import Requests from "views/admin/requests";
import Users from "views/admin/user";
import ViewUser from "views/admin/user/view";
// import Terms from "views/admin/cms/terms";
import Blocks from "views/admin/cms/blocks";
import Pages from "views/admin/cms/pages";
import Notifications from "views/admin/notifications";
import CustomNotifications from "views/admin/notifications/custom";
import PostulationsPage from "views/admin/postulations";
import EditPublicationPage from "views/admin/publication/edit";
import Specialties from "views/admin/specialties/Specialties";
import Demography from "views/admin/reports/components/Demography";
import Technologies from "views/admin/reports/components/Technologies";
import ContinousTraining from "views/admin/reports/components/ContinousTraining";

const ProtectedRoute = () => {
  let navigate = useNavigate();

  const data = localStorage.getItem('@user:key')
  const userData = JSON.parse(data)

  // console.log(data)
  if (!userData?.isLogged) {
    // console.log("TO LOGIN")
    // navigate(SITE_URL, {
    //   replace: true
    // })
    // window.location.href = SITE_URL
    return <Navigate to={routesNames.LOGIN} replace />;
  }

  return <Outlet />;
};

const App = () => {
  return (
    <Routes>
      <Route path={routesNames.LOGIN} element={<SignIn />} />

      <Route element={<ProtectedRoute />}>

        <Route element={<AdminLayout />}>
          <Route path={routesNames.DASHBOARD} element={<Dashboard />} />
          <Route path={routesNames.REQUESTS} element={<Requests />} />
          <Route path={routesNames.PUBLICATIONS} element={<Publications />} />
          <Route path={`${routesNames.PUBLICATION}/:id`} element={<Publication />} />
          <Route path={`${routesNames.PUBLICATION_EDIT}/:id`} element={<EditPublicationPage />} />
          <Route path={`${routesNames.PLAN}/:id`} element={<Plan />} />


          {/* <Route path={routesNames.PLANS_REQUEST} element={<Plans />} /> */}
          <Route path={routesNames.CONTABLE} element={<Dashboard />} />
          <Route path={routesNames.USERS} element={<Users />} />
          <Route path={`${routesNames.USER}/:id`} element={<ViewUser />} />
          <Route path={routesNames.ESPECIALIDADES} element={<Specialties />} />
          <Route path={routesNames.FAQS} element={<Faqs />} />
          <Route path={routesNames.PAGES} element={<Pages />} />
          <Route path={routesNames.BLOCKS} element={<Blocks />} />
          <Route path={routesNames.CONTACT} element={<Contact />} />
          <Route path={routesNames.TRANSFER_DATA} element={<Transfer />} />
          <Route path={routesNames.PLANS} element={<CMS />} />
          <Route path={routesNames.SETTINGS} element={<Dashboard />} />
          <Route path={routesNames.SETTINGS_PLANS} element={<PlansSettings />} />
          <Route path={routesNames.SETTINGS_PUBLICATIONS} element={<PublicationsSettings />} />
          <Route path={routesNames.NOTIFICATIONS_ADMIN} element={<Notifications />} />
          <Route path={routesNames.NOTIFICATIONS_INST} element={<Notifications />} />
          <Route path={routesNames.NOTIFICATIONS_USER} element={<Notifications />} />
          <Route path={routesNames.NOTIFICATIONS_CUSTOM} element={<CustomNotifications />} />
          <Route path={routesNames.POSTULATIONS} element={<PostulationsPage />} />
          <Route path={routesNames.REPORTS_DEMOGRAPHY} element={<Demography />} />
          <Route path={routesNames.REPORTS_TECHNOLOGIES} element={<Technologies />} />
          <Route path={routesNames.REPORTS_CONTINUOUS_TRAINING} element={<ContinousTraining />} />


        </Route>
        {/* <Route path="auth/*" element={<AuthLayout />} /> */}
        {/* <Route path="admin/*" element={<AdminLayout />} /> */}
        {/* <Route path="rtl/*" element={<RtlLayout />} />*/}
        <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
      </Route>
    </Routes>
  );
};

export default App;
