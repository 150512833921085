import { HttpGet } from "./Http"


export const getPostulantes = async () => {
    try {
        const data = await HttpGet(`admin/reportes/postulantes`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getDemografia = async () => {
    try {
        const data = await HttpGet(`admin/reportes/demografia`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getTecnologias = async () => {
    try {
        const data = await HttpGet(`admin/reportes/tecnologias`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getFormacionContinua = async () => {
    try {
        const data = await HttpGet(`admin/reportes/formacionContinua`, false)
        return data;
    } catch (error) {
        return error
    }
}