import { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { Checkbox, Divider, Modal, Slider } from "antd";
import { useQuill } from "react-quilljs";
import Editor from "components/editor";
import Button from "components/Button";
import imgConfidencial from "assets/img/institucion-confidencial.png";
import placeholder_image from "assets/img/placeholder-image-2.jpg";
import './ModalGraphicResources.css';
import { HttpGet } from "../../../../services/Http";

const PUBLIC_URI = process.env.REACT_APP_PUBLIC_URI_IMAGES;

export default function ModalGraphicResources({ isModalOpen, setIsModalOpen, data, type = 'post', setType }) {

    const postRef = useRef();
    const storyRef = useRef();

    const inputRef1 = useRef();
    const inputRef2 = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const [institucion, setInstitucion] = useState("");
    const [ubicacion, setUbicacion] = useState("");
    const [puesto, setPuesto] = useState("");
    const [requisitos, setRequisitos] = useState("");
    const [hasSetContent, setHasSetContent] = useState(false);
    const [confidencial, setConfidencial] = useState(false);

    const [imagen, setImagen] = useState("");
    const [imagen2, setImagen2] = useState("");
    const [imgSize, setImgSize] = useState("150");
    const [imgSize2, setImgSize2] = useState("150");
    const [isImage2Enabled, setIsImage2Enabled] = useState(false);

    const [ejeYImg1, setEjeYImg1] = useState(260);
    const [ejeYImg2, setEjeYImg2] = useState(350);
    const [ejeYImgDoble, setEjeYImgDoble] = useState(250);
    const [displayImgs, setDisplayImgs] = useState('block');
    const [displayDoble, setDisplayDoble] = useState('none');

    const theme = 'snow';
    const placeholder = 'Contenido de los requisitos';
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    };

    const { quillRef, quill } = useQuill({ theme, modules, placeholder });

    const handleClose = () => {
        setIsModalOpen(false);
        setType(null);
    };

    const handleDownload = async () => {
        try {
            if (type === 'post') {
                if (postRef?.current) {
                    const canvas = await html2canvas(postRef?.current, {
                        useCORS: true,
                        allowTaint: true,
                        scale: 2,
                    });
                    const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
                    const link = document.createElement("a");
                    link.href = dataUrl;

                    const name = confidencial
                        ? `Confidencial-${ubicacion}`
                        : `${data?.nombre.replace(/ /g, "_")}_${data?.cuenta?.nombre.replace(/ /g, "_")}.jpg`

                    link.download = name;
                    link.click();
                }
            } else {
                if (storyRef?.current) {
                    const canvas = await html2canvas(storyRef?.current);
                    const dataUrl = canvas.toDataURL("image/jpeg", 1.0);

                    const link = document.createElement("a");
                    link.href = dataUrl;

                    const name = confidencial
                        ? `Confidencial-${ubicacion}`
                        : `${data?.nombre.replace(/ /g, "_")}_${data?.cuenta?.nombre.replace(/ /g, "_")}.jpg`

                    link.download = name;
                    link.click();
                }
            }
        } catch (error) {
            console.error("Error al capturar el div:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // const fetchImageAsBase64 = async (url) => {
    //     try {
    //         const response = await fetch(url, { mode: 'cors' });
    //         const blob = await response.blob();
    //         return new Promise((resolve, reject) => {
    //             const reader = new FileReader();
    //             reader.onloadend = () => resolve(reader.result);
    //             reader.onerror = () => reject("Error al convertir la imagen a Base64");
    //             reader.readAsDataURL(blob);
    //         });
    //     } catch (error) {
    //         console.error("Error al obtener la imagen remota:", error);
    //         return null;
    //     }
    // };

    const fetchLogo = async () => {
        try {
            const response = await HttpGet(`account/${data?.cuenta_id}/logoBase64`, false)
            setImagen(response[0]?.$base64);
        } catch (error) {
            return error
        }
    };

    useEffect(() => {
        if (data) {
            fetchLogo()

            if (data?.privada) {
                setInstitucion("Institución Confidencial");
                setImagen(imgConfidencial);
                setImagen2("");
            } else {
                setInstitucion(data?.cuenta?.nombre);
                // loadImage();
                setImagen2(placeholder_image)
            }

            setUbicacion(data?.cuenta?.localidad);
            setPuesto(data?.nombre);
            setRequisitos(data?.descripcion);
            setConfidencial(data?.privada);

        }
    }, [data]);

    // const loadImage = async () => {
    //     if (data?.cuenta?.logo_url) {
    //         // const imageBase64 = await fetchImageAsBase64(`${PUBLIC_URI}uploads/${data?.cuenta?.logo_url}/`);
    //         // if (imageBase64) {
    //         //     setImagen(imageBase64);
    //         // } else {
    //         //     setImagen(`${PUBLIC_URI}uploads/${data?.cuenta?.logo_url}`);
    //         // }
    //         setImagen(`${PUBLIC_URI}uploads/${data?.cuenta_id}`);

    //     }
    // };

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                setRequisitos(quill.root.innerHTML);
            });
        }
    }, [quill]);

    const handleImageChange = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => setImage(e.target.result);
            reader.readAsDataURL(file);
        } else {
            // Cargar la imagen desde URL pública como base64
            fetch(`${PUBLIC_URI}uploads/${data?.cuenta?.logo_url}`)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = (e) => setImage(e.target.result);
                    reader.readAsDataURL(blob);
                })
                .catch(err => console.error('Error al cargar imagen:', err));
        }
    };

    const handleRemoveImage = (setImage, inputRef) => {
        setImage(placeholder_image);
        if (inputRef && inputRef.current) {
            inputRef.current.value = null; // Restablecer el valor del input
        }
    };


    const checkHabilitar = (checked) => {

        if (checked) {
            setIsImage2Enabled(checked);
            setDisplayImgs('none');
            setDisplayDoble('block')
        } else {
            setIsImage2Enabled(checked);
            setDisplayImgs('block');
            setDisplayDoble('none')
        }
    }

    useEffect(() => {
        if (quill && requisitos && !hasSetContent) {
            quill.clipboard.dangerouslyPasteHTML(''); // Limpia el contenido actual
            quill.clipboard.dangerouslyPasteHTML(requisitos); // Establece el contenido inicial
            setHasSetContent(true); // Marca que ya se configuró el contenido
        }
    }, [quill, requisitos, hasSetContent]);

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                setRequisitos(quill.root.innerHTML); // Actualiza el estado correctamente
            });
        }
    }, [quill]);

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleClose}
            width="95%"
            onOk={handleDownload}
            okText={<p className='text-black'>{isLoading ? "Generando..." : "Descargar como JPG"}</p>}
            cancelText="Cancelar"
            okButtonProps={{
                className: "bg-main"
            }}
        >
            <main className='flex flex-row lg-max:flex-col w-full pt-5 gap-5'>
                {/* INPUTS */}
                <section className='flex flex-col items-start justify-start w-full bg-gray-50 p-5 rounded-lg'>
                    {!confidencial &&
                        <>
                            <label className='text-sm font-semibold mb-1'>Institución</label>
                            <input
                                value={institucion}
                                onChange={(e) => setInstitucion(e.target.value)}
                                type="text"
                                className="border w-full rounded-md mb-4 px-2 py-1"
                                maxLength={50}
                            />
                        </>
                    }

                    <label className='text-sm font-semibold mb-1'>Ubicación</label>
                    <input
                        value={ubicacion}
                        onChange={(e) => setUbicacion(e.target.value)}
                        type="text"
                        className="border w-full rounded-md mb-4 px-2 py-1"
                        maxLength={20}
                    />

                    {!confidencial &&
                        <>
                            <label className='text-sm font-semibold mb-1'>Imagen 1</label>

                            {/* <section className="flex w-full">
                                <input
                                    ref={inputRef1}
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => { handleImageChange(e, setImagen) }}
                                    className="border w-[70%] rounded-md mb-4 px-2 py-1"
                                />

                                <Button label="Borrar Imagen 1" onClick={() => handleRemoveImage(setImagen, inputRef1)} className="mb-4 bg-red-500 text-white" />
                            </section> */}

                            <label className='text-sm font-semibold mb-1'>Tamaño de Imagen 1</label>
                            <Slider
                                min={50}
                                max={500}
                                value={imgSize}
                                onChange={setImgSize}
                                className="mb-4"
                                style={{ width: '100%' }}
                            />

                            <div style={{ display: displayImgs }}>
                                <label className='text-sm font-semibold mb-1'>Eje Y imagen 1</label>
                                <Slider
                                    min={1}
                                    max={500}
                                    value={ejeYImg1}
                                    onChange={setEjeYImg1}
                                    className="mb-4"
                                    style={{ width: '100%' }}
                                />
                            </div>


                            <Divider />

                            <Checkbox
                                checked={isImage2Enabled}
                                onChange={(e) => checkHabilitar(e.target.checked)}
                                className="mb-4"
                            >
                                Habilitar 2da imagen
                            </Checkbox>

                            {isImage2Enabled &&
                                <>
                                    <label className='text-sm font-semibold mb-1'>Imagen 2</label>

                                    <section className="flex w-full">
                                        <input
                                            ref={inputRef2}
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => { handleImageChange(e, setImagen2) }}
                                            className="border w-[70%] rounded-md mb-4 px-2 py-1"
                                        />

                                        <Button label="Borrar Imagen 2" onClick={() => handleRemoveImage(setImagen2, inputRef2)} className="mb-4 bg-red-500 text-white" />
                                    </section>

                                    <label className='text-sm font-semibold mb-1'>Tamaño de Imagen 2</label>
                                    <Slider
                                        min={50}
                                        max={500}
                                        value={imgSize2}
                                        onChange={setImgSize2}
                                        className="mb-4"
                                        style={{ width: '100%' }}
                                    />

                                    <div style={{ display: displayImgs }}>
                                        <label className='text-sm font-semibold mb-1'>Eje Y imagen 2</label>
                                        <Slider
                                            min={1}
                                            max={500}
                                            value={ejeYImg2}
                                            onChange={setEjeYImg2}
                                            className="mb-4"
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                    <div style={{ display: displayDoble }}>
                                        <label className='text-sm font-semibold mb-1'>Eje Y imágenes</label>
                                        <Slider
                                            min={1}
                                            max={500}
                                            value={ejeYImgDoble}
                                            onChange={setEjeYImgDoble}
                                            className="mb-4"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </>
                            }
                        </>
                    }

                    <Divider />

                    <label className='text-sm font-semibold mb-1'>Puesto</label>
                    <input
                        value={puesto}
                        onChange={(e) => setPuesto(e.target.value)}
                        type="text"
                        className="border w-full rounded-md mb-4 px-2 py-1"
                        maxLength={100}
                    />

                    <label className='text-sm font-semibold mb-1'>Requisitos</label>
                    <Editor classname=" bg-white w-full" quillRef={quillRef} />
                </section>

                {/* RESULTADO */}
                <section className='w-full h-full bg-gray-50 p-5 rounded-lg lg-max:flex lg-max:flex-col lg-max:items-center'>
                    <label className='text-lg font-semibold mb-1'>Vista previa (los márgenes se ajustan al descargar la imagen)</label>

                    {type === 'post' ?
                        //                                                                              IG POST
                        <main ref={postRef} className='post-card mt-3 bg-white lg-max:scale-50 relative' style={{ width: '1080px', height: '1080px' }}>
                            <section className='absolute w-full h-[130px] flex-col z-10' style={{ top: '60px', left: '0', right: '0', marginTop: '30px' }}>
                                <p style={{ fontSize: '46px' }} className="font-bold text-black text-center font-encode">{institucion}</p>
                                <p style={{ fontSize: '44px', lineHeight: '30px', marginTop: '20px' }} className="text-black text-center font-encode">[{ubicacion}]</p>
                            </section>

                            {!isImage2Enabled ?
                                <section className='w-full flex justify-center items-center absolute' style={{ top: ejeYImg1, marginTop: '10px' }}>
                                    <img
                                        style={{ width: `${parseInt(imgSize) + 30}px` }}
                                        className="aspect-auto z-0"
                                        src={imagen}
                                        alt='Logo'
                                    />
                                </section>
                                :
                                <section className='w-full flex justify-center items-center gap-[20px] absolute' style={{ top: ejeYImgDoble, marginTop: '10px' }}>
                                    <img
                                        style={{ width: `${parseInt(imgSize) + 30}px` }}
                                        className="aspect-auto z-0"
                                        src={imagen}
                                        alt='Logo'
                                    />

                                    <img
                                        style={{ width: `${parseInt(imgSize2) + 30}px` }}
                                        className="aspect-auto z-0"
                                        src={imagen2}
                                        alt='Logo'
                                    />
                                </section>
                            }

                            <section className='w-full absolute' style={{ top: '420px', left: '0', right: '0', marginTop: '25px' }}>
                                <p style={{ fontSize: '50px', lineHeight: '64px' }} className="font-bold text-center text-black font-encode">{puesto}</p>
                            </section>

                            <section className='w-[940px] h-[300px] absolute' style={{ top: '600px', left: '85px', marginTop: '25px' }}>
                                <p className="quill-content text-base m-auto font-medium text-black font-encode" dangerouslySetInnerHTML={{ __html: requisitos }}></p>
                            </section>
                        </main>
                        :
                        //                                                                              IG STORY
                        // <main ref={storyRef} className=".story-card-2" style={{ width: '1080px', height: '1920px', position: 'relative' }}>
                        //     <section className='absolute top-[140px] right-[124px]'>
                        //         <img
                        //             style={{ width: `${parseInt(imgSize) + 80}px` }}
                        //             className="aspect-auto z-0"
                        //             src={imagen}
                        //             alt='Logo'
                        //         />
                        //     </section>

                        //     <section className="w-[620px] h-[120px]" style={{ fontSize: '46px', position: 'absolute', top: '590px', left: '130px' }}>
                        //         <p className="font-semibold uppercase" style={{ lineHeight: '50px' }}>{puesto}</p>
                        //     </section>

                        //     <section style={{ position: 'absolute', top: '884px', left: '160px' }}>
                        //         <h2 className="font-medium text-[40px] inst-post">{confidencial ? `CONFIDENCIAL - ${ubicacion}` : institucion}</h2>
                        //     </section>

                        //     <section style={{ position: 'absolute', top: '1040px', left: '100px' }}>
                        //         <p className="quill-content req-post m-auto w-[960px] font-medium text-[40px]" dangerouslySetInnerHTML={{ __html: requisitos }}></p>
                        //     </section>
                        // </main>

                        <main ref={storyRef} className="story-card" style={{ width: '1080px', height: '1920px', position: 'relative' }}>
                            <section className='absolute w-full h-[130px] flex-col z-10' style={{ top: '152px', left: '0', right: '0', marginTop: '30px' }}>
                                <p style={{ fontSize: '50px' }} className="font-bold text-black text-center font-encode mt-5">{institucion}</p>
                                <p style={{ fontSize: '50px', lineHeight: '30px' }} className="text-black text-center font-encode">[{ubicacion}]</p>
                            </section>

                            {!isImage2Enabled ?
                                <section className='w-full flex justify-center items-center absolute' style={{ top: ejeYImg1, marginTop: '10px' }}>
                                    <img
                                        style={{ width: `${parseInt(imgSize) + 150}px`, marginTop: '32px' }}
                                        className="aspect-auto z-0"
                                        src={imagen}
                                        alt='Logo'
                                    />
                                </section>
                                :
                                <section className='w-full flex justify-center items-center gap-[20px] absolute' style={{ top: ejeYImgDoble, marginTop: '10px' }}>
                                    <img
                                        style={{ width: `${parseInt(imgSize) + 150}px` }}
                                        className="aspect-auto z-0"
                                        src={imagen}
                                        alt='Logo'
                                    />

                                    <img
                                        style={{ width: `${parseInt(imgSize2) + 150}px` }}
                                        className="aspect-auto z-0"
                                        src={imagen2}
                                        alt='Logo'
                                    />
                                </section>
                            }

                            <section className='w-[940px] m-auto absolute' style={{ top: '680px', left: '0', right: '0' }}>
                                <p style={{ fontSize: '40px', lineHeight: '68px' }} className="font-bold text-center text-black font-encode">{puesto}</p>
                            </section>

                            <section className='w-[940px] h-[300px] absolute' style={{ top: '880px', left: '85px', marginTop: '30px' }}>
                                <p className="quill-content req-post text-base m-auto font-medium text-black font-encode" style={{ lineHeight: '32px !important' }} dangerouslySetInnerHTML={{ __html: requisitos }}></p>
                            </section>
                        </main>
                    }
                </section>
            </main>
        </Modal>
    );
}
