import * as XLSX from 'xlsx-js-style'
import { Button } from "antd"
import { FaFileExcel } from "react-icons/fa";

const ExportExcel = ({ data, fileName, title }) => {

    const handleExport = () => {
        const dataArray = Array.isArray(data) ? data : [data];

        // Crear hoja de Excel
        const ws = XLSX.utils.json_to_sheet(dataArray.map(row => {
            return Object.keys(row).reduce((acc, key) => {
                const newKey = key.replace(/^(\d+_)/, '');
                if (row[key] === true) {
                    acc[newKey] = 'Sí';
                } else if (row[key] === false) {
                    acc[newKey] = 'No';
                } else {
                    acc[newKey] = row[key];
                }
                return acc;
            }, {});
        }));

        const range = XLSX.utils.decode_range(ws['!ref']);

        // Estilos para el encabezado
        const headerStyle = {
            fill: { fgColor: { rgb: "284473" } },
            font: { color: { rgb: "FFFFFF" }, sz: 12, bold: true },
            alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
            border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
        };

        // Estilos para las celdas
        const cellStyle = {
            fill: { fgColor: { rgb: "FFFFFF" } },
            font: { color: { rgb: "000000" }, sz: 12 },
            alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
            numFmt: '@',
            border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
        };

        // Aplicar estilos a las celdas
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cell = ws[XLSX.utils.encode_cell({ r: row, c: col })];
                if (cell) {
                    cell.s = (row === range.s.r) ? headerStyle : cellStyle;
                }
            }
        }

        // Ajustar el ancho de las columnas
        ws['!cols'] = Array.from({ length: range.e.c - range.s.c + 1 }).map(() => ({ width: 20 }));

        // Crear y descargar el archivo Excel
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hoja 1");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    return (
        <Button
            onClick={handleExport}
            className="text-xs mr-2 bg-green-500 text-white  flex items-center disabled:opacity-80 disabled:cursor-wait"
        >
            {title}
            <FaFileExcel className="ml-2" />
        </Button>
    );
};

export default ExportExcel;
