import { useEffect, useState } from "react";
import { getFormacionContinua } from "services/Reports";
import { Table } from "antd";
import ExportExcel from "components/excel/ExportExcel";
import Loader from "components/loader";

const ContinousTraining = () => {
  const [formacionContinua, setFormacionContinua] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFormacionContinua = async () => {
    try {
      setIsLoading(true)
      const data = await getFormacionContinua()
      setIsLoading(false)

      if (data) {
        setFormacionContinua(data[0]);
        dataExport(data[0])
      }

    } catch (error) {
      return error
    }
  }

  const columns = [
    {
      title: 'User_ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Username',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
    },
    {
      title: 'Apellido',
      dataIndex: 'apellido',
    },
    {
      title: 'Edad',
      dataIndex: 'Edad',
      sorter: (a, b) => a.Edad - b.Edad,
    },
    {
      title: 'Género',
      dataIndex: 'Genero',
    },
    {
      title: 'Ciudad',
      dataIndex: 'localidad',
    },
    {
      title: 'Provincia',
      dataIndex: 'provincia',
    },
    {
      title: 'Tiene cursos y certificacione adicionales',
      dataIndex: 'Cursos',
      sorter: (a, b) => a.Cursos - b.Cursos,
    },
    {
      title: 'Tiene cursos y certificacione adicionales inicio > 2020',
      dataIndex: 'Cursos_2020',
      sorter: (a, b) => a.Cursos_2020 - b.Cursos_2020,
    },
  ];

  const dataExport = (data) => {
    const exportData = data.map((item) => {
      return {
        'User_ID': item.user_id,
        'Username': item.name,
        'Email': item.email,
        'Nombre': item.nombre,
        'Apellido': item.apellido,
        'Edad': item.Edad,
        'Género': item.Genero,
        'Ciudad': item.localidad,
        'Provincia': item.provincia,
        'Tiene cursos y certificacione adicionales': item.Cursos,
        'Tiene cursos y certificacione adicionales inicio > 2020': item.Cursos_2020,
      }
    })

    setDataExcel(exportData)
  }

  useEffect(() => {
    fetchFormacionContinua()
  }, [])

  return (
    <>
      <div className="flex flex-row gap-5">
        <h1 className="text-3xl font-medium mb-5">/ Formación continua</h1>
        <ExportExcel data={dataExcel} fileName={"Formacion_continua_" + new Date().toLocaleDateString()} title="Descargar Excel" />
      </div>

      {isLoading ? <Loader /> :
        <Table
          columns={columns}
          dataSource={formacionContinua}
          onChange={(pagination, filters, sorter, extra) => {
            dataExport(extra.currentDataSource)
          }}
        />
      }
    </>
  );
};

export default ContinousTraining;
